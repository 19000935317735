<div class="row section">
  <div class="col-sm-10 offset-sm-1 col-12">
    <div *ngIf="loader" class="col-sm-10 offset-sm-1 col-12 text-center">
      <img src="../../../../assets/loader/loader.gif" alt="loader" />
    </div>
    <!-- for live page -->
    <div *ngIf="!loader">
      <div class="title">
        <h1>{{ "live.title" | translate }}</h1>
      </div>
      <div *ngIf="!error">
        <div class="list">
          <div class="live-section">
            <a
              [ngClass]="{
                'tag status premium': firstItem.purchase_type == 'premium',
                'tag status standard ': firstItem.purchase_type == 'standard'
              }"
              [routerLink]="['/live-watch']"
              [queryParams]="{ watch_id: firstItem.id }"
            >
              <div>
                <img
                  [src]="firstItem.icon_img"
                  id="channel-thumbnail"
                  [alt]="firstItem.title"
                  class="channel-thumbnail"
                />
              </div>
            </a>

            <div class="description" id="description">
              <div class="tags">
                <div
                  style="margin-right: 5px"
                  *ngIf="firstItem.show_tag"
                  class="c-tag"
                >
                  <img src="{{ firstItem.show_tag }}" alt="tags" />
                </div>
                <div *ngIf="firstItem.live_tag" class="live-tag">
                  <img src="{{ firstItem.live_tag }}" alt="tags" />
                </div>
              </div>
              <div class="firsttitle">
                <h3>
                  {{ firstItem.title }}
                </h3>
                <span> {{ firstItem.start_time | date : "yyyy-MM-dd" }}</span>
              </div>
              <div
                class="link d-lg-block d-sm-none"
                [innerHTML]="firstItem.description"
              ></div>
            </div>
          </div>
        </div>
        <hr />

        <div class="archievelist">
          <div class="row list">
            <ng-container *ngFor="let item of channelData; let i = index">
              <div *ngIf="i > 0" class="col-lg-3 col-md-6 padding-0">
                <a
                  [ngClass]="{
                    'tag status premium': item.purchase_type == 'premium',
                    'tag status standard ': item.purchase_type == 'standard'
                  }"
                  style="text-decoration: none; color: black"
                  [routerLink]="['/live-watch']"
                  [queryParams]="{ watch_id: item.id }"
                >
                  <div class="archieve-list">
                    <div style="position: relative">
                      <img
                        class="archive-thumbnail"
                        [src]="item.icon_img"
                        alt=""
                      />
                    </div>

                    <div style="display: flex">
                      <div class="archive-des">
                        <div class="wtags">
                          <div
                            style="margin-right: 5px"
                            *ngIf="item.show_tag"
                            class="c-tag"
                          >
                            <img src="{{ item.show_tag }}" alt="tags" />
                          </div>
                          <div *ngIf="item.live_tag" class="live-tag">
                            <img src="{{ item.live_tag }}" alt="" />
                          </div>
                        </div>
                        <p class="des-title">{{ item.title }}</p>
                        <p class="date">
                          {{ item.start_time | date : "yyyy-MM-dd" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="meta.current_page >= total_pages">
        <p class="text-center">No more Videos Avaialble !!</p>
      </div> -->
    </div>
    <div class="error" *ngIf="error">
      <p class="text-center">{{ "live.message" | translate }}</p>
      <div class="langbuttons">
        <button [routerLink]="['/']" type="submit" class="btn">
          <a><span class="material-icons"> chevron_left </span> 戻る</a>
        </button>
      </div>
    </div>
  </div>
</div>
