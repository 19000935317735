<div class="container notfound">
  <h1>{{ "pagenotfound.title" | translate }}</h1>
  <p>
    {{ "pagenotfound.message" | translate }}
  </p>

  <div class="langbuttons">
    <button [routerLink]="['/']" type="submit" class="btn">
      <a><span class="material-icons"> chevron_left </span>
        {{ "button.return" | translate }}</a>
    </button>
  </div>
</div>