<div class="row">
  <div class="col-sm-10 offset-sm-1 col-12">
    <div class="search-section">
      <div class="title">
        <h6>{{ searchTitle }}</h6>
      </div>
      <div class="search-list">
        <div *ngIf="searchData.length >= 1" class="row gap-0">
          <div
            *ngFor="let item of searchData"
            class="col-lg-3 col-md-6 col-sm-12 padding-0 list"
          >
            <div *ngIf="item.type == 'live'">
              <a
                [ngClass]="{
                  'tag status premium': item.purchase_type == 'premium',
                  'tag status standard ': item.purchase_type == 'standard'
                }"
                [routerLink]="['/live-watch']"
                [queryParams]="{ watch_id: item.id }"
              >
                <div style="position: relative">
                  <img class="thumbnail-image" [src]="item.icon_img" alt="" />
                  <div class="tags">
                    <div
                      style="margin-right: 5px"
                      *ngIf="item.show_tag"
                      class="c-tag"
                    >
                      <img src="{{ item.show_tag }}" alt="tags" />
                    </div>
                    <div class="livetag">
                      <img
                        src="../../../../assets/sidebar/livetag.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div style="display: flex">
                    <div class="archive-des">
                      <p class="text">{{ item.title }}</p>
                      <p class="date">{{ item.date }}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div *ngIf="item.type == 'video'">
              <a
                [ngClass]="{
                  'tag status premium': item.purchase_type == 'premium',
                  'tag status standard ': item.purchase_type == 'standard'
                }"
                [routerLink]="['/watch']"
                [queryParams]="{ watch_id: item.id }"
              >
                <div style="position: relative">
                  <img class="thumbnail-image" [src]="item.icon_img" alt="" />
                  <div class="tags">
                    <div *ngIf="item.show_tag" class="c-tag">
                      <img src="{{ item.show_tag }}" alt="tags" />
                    </div>
                  </div>

                  <div style="display: flex">
                    <div class="archive-des">
                      <p class="text">{{ item.title }}</p>
                      <p class="date">{{ item.date }}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="searchData.length <= 0" class="row">
          <div class="col-sm-10 offset-sm-1 col-12">
            <div class="notfound">
              <h3>Result not found !!!!</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="langbuttons">
        <button [routerLink]="['/']" type="submit" class="btn">
          <a
            ><span class="material-icons"> chevron_left </span>
            {{ "button.return" | translate }}</a
          >
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="col-sm-10 offset-sm-1 col-12">
    <div class="text-center">
      <img src="../../../../assets/loader/loader.gif" alt="loader" />
    </div>
  </div> -->
</div>
