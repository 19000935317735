import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[app-parent-item]',
  templateUrl: './parent-item.component.html',
  styleUrls: ['./parent-item.component.css']
})
export class ParentItemComponent implements OnInit {
  @Input() item: any = {
  };
  @Input() lang: any = ''
  @Input() link: any = 'parent-channel'
  constructor() { }

  ngOnInit(): void {
  }

}
