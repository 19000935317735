<div class="footer">
  <div class="footer-section">
    <div [routerLink]="['/terms']" class="footer-item">
      <p>
        {{ "footer.terms" | translate }}
      </p>
      <span class="material-icons"> chevron_right </span>
    </div>
    <div [routerLink]="['/privacy-policy']" class="footer-item">
      <p>
        {{ "footer.privacy_policy" | translate }}
      </p>
      <span class="material-icons"> chevron_right </span>
    </div>

    <div
      [routerLink]="['/laws']"
      *ngIf="langSerivce.getLang() != 'en'"
      class="footer-item"
    >
      <p>特定商取引法に基づく表記</p>
      <span class="material-icons"> chevron_right </span>
    </div>

    <div
      class="footer-item"
      (click)="openNewLink()"
      *ngIf="langSerivce.getLang() != 'en'"
    >
      <p>
        {{ "footer.notation" | translate }}
      </p>
      <span class="material-icons"> chevron_right </span>
    </div>
  </div>
  <div class="language-setting">
    <div class="language-section" *ngIf="showLoginButton">
      <div class="japanese">
        <button
          type="button"
          (click)="changeLanguagetojp('jp')"
          [ngClass]="japan ? 'btn active' : 'btn'"
        >
          日本語
        </button>
      </div>
      <div class="english">
        <button
          type="button"
          (click)="changeLanguagetoeg('en')"
          [ngClass]="english ? 'btn active' : 'btn'"
        >
          English
        </button>
      </div>
    </div>
    <div class="copyright">
      <p>© DARTSLIVE Co., Ltd.</p>
    </div>
  </div>
</div>

<script
  src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/js/all.min.js"
  integrity="sha512-Tn2m0TIpgVyTzzvmxLNuqbSJH3JP8jm+Cy3hvHrW7ndTDcJ1w5mBiksqDBb8GpE2ksktFvDB/ykZ0mDpsZj20w=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
></script>
