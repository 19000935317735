import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category } from 'src/app/shared/models/video.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getCategoriesList(): Observable<Category[]> {
    return this.http.get<Category[]>(this.baseUrl + 'categories/');
  }
}
