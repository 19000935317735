import { Component, OnInit } from '@angular/core';
import { ChannelService } from 'src/app/services/channelservice/channel.service';

@Component({
  selector: 'app-multi-live-events',
  templateUrl: './multi-live-events.component.html',
  styleUrls: ['./multi-live-events.component.css']
})
export class MultiLiveEventsComponent implements OnInit {
  allData: any = [];

  constructor(private homeService: ChannelService) { }

  ngOnInit(): void {
    this.homeService.getMultiLiveEvents('').subscribe(data=>{
      this.allData = data['data']
    })
  }

}
