// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.dev.dartslive.tv/api/v1/',
  apiV2Url: 'https://api.dev.dartslive.tv/api/v2/',
  defaultLang: 'jp',
  firebase:  {
    apiKey: "AIzaSyAz2bfsmVNUAgYnhC6RQyurcpQtMSLkd9M",
    authDomain: "dartslive-ott.firebaseapp.com",
    databaseURL: "https://dartslive-ott-default-rtdb.firebaseio.com",
    projectId: "dartslive-ott",
    storageBucket: "dartslive-ott.appspot.com",
    messagingSenderId: "677307550515",
    appId: "1:677307550515:web:024ce2d98fd6a94a05cc46"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
