<div class="row">
  <div class="col-sm-10 offset-sm-1 col-12">
    <div class="youtube-player">
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/eI4an8aSsgw"
      >
      </iframe>
    </div>
  </div>
</div>
