import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import * as $ from 'jquery';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css'],
})
export class LiveComponent implements OnInit {
  page: any = 1;
  data: any;
  meta: any = {
    current_page: 1,
    last_page: 1,
  };
  public channelData: any = [];
  public firstItem: any = {};
  public errorObject: any = null;
  public live: any = true;
  public loader: any = false;
  public lang: any = '';
  public error: any = false;
  public total_pages: any = 0;
  nextPage: any;
  currentPosition = window.innerHeight;
  constructor(
    private channelService: ChannelService,
    public translate: TranslateService,
    private route: Router,
    private langSerivce: LanguageService,
    private titleServie: Title
  ) {
    this.lang = this.langSerivce.getLang();
    translate.setDefaultLang(this.lang);
  }

  @HostListener('window:scroll', ['$event.target']) // for window scroll events
  scroll(e: any) {
    if ($(window).scrollTop() + $(window).height() >= $(document).height()) {
      if (this.meta.current_page < this.total_pages) {
        this.meta.current_page++;
        this.onLoadMore();
      } else {
        this.loader = false;
      }
    }
  }

  ngOnInit(): void {
    this.titleServie.setTitle('LIVE | ダーツライブTV ');
    this.onLoadMore();
  }

  onLoadMore() {
    this.loader = true;
    this.channelService
      .getLive(this.meta.current_page)
      .pipe()
      .subscribe(
        (res: any) => {
          if (res.data.length <= 0) {
            this.loader = false;
            this.error = true;
          } else {
            this.error = false;
            this.loader = false;
            this.meta = res.meta;
            this.nextPage = res.links.next;
            if (this.channelData.length) {
              res.data.forEach((element: any) => {
                this.channelData.push(element);
              });
            } else {
              this.channelData = res.data;
              this.total_pages = res.meta.last_page;
            }

            this.firstItem = res.data[0];
          }
        },
        (error: any) => {}
      );
  }
}
