import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  recordwatchHistory(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'history/watch', data);
  }

  getStreamUrl(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'stream/video/url/' + id)
      .pipe(map((data: any) => data.data));
  }

  getUrl(id: any): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + 'video/url/' + id)
      .pipe(map((data: any) => data.data));
  }

  getLiveEventStreamUrl(id: any): Observable<any> {
    return this.http.get(this.baseUrl + 'stream/live_event/url/' + id).pipe(
      map((data: any) => data.data)
    );
  }

  getLiveEventUrl(id: any): Observable<any> {
    return this.http
      .get(this.baseUrl + 'live_event/url/' + id)
      .pipe(map((data: any) => data.data));
  }


  getMultiLiveLiveEvents(id: any): Promise<any> {
    return firstValueFrom(this.http.get(this.baseUrl + 'stream/' + id + '/events').pipe(
      map((data: any) => {
        data.data.map((data: any) => {
          data['cover_image'] = data['thumbnail']
          return data;
        });
        return data.data;
      })
    ));
  }
}
