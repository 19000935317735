import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { HomeserviceService } from 'src/app/services/homesevice/homeservice.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebarservice/sidebar.service';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import * as $ from 'jquery';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';
import { Banner, Category, LiveEvent } from 'src/app/shared/models/video.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  public bannerList: Banner[] = [];
  public categoriesList: Category[] = []
  public videoCategoriesList: any =[];
  public archiveData: any = [];
  public multiLiveEvents: any = [];
  public liveData: LiveEvent[] = [];
  public sidebardata: Category[] = [];
  public alert: any = '';
  public bannerLoader: any = false;
  public mainLoader: any;
  public maxItems: any;
  public lang: any = '';
  loading: boolean = true;
  public currentIndex = 0;
  public _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
  public playStoreUrl: any = '';
  constructor(
    private homeService: HomeserviceService,
    private sidebar: SidebarService,
    public translate: TranslateService,
    private langSerivce: LanguageService,
    private archieve: ChannelService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private dataservice: DataShareService,
    private sanitizer: DomSanitizer
  ) {
    this.lang = this.langSerivce.getLang();
    this.playStoreUrl = this.sanitizer.bypassSecurityTrustUrl('https://play.google.com/store/apps/details?id=com.dartslive.tv');
    // this.dataservice.scrollStatus.subscribe((res:any) => {
    //   if(res) {
    //     window.scrollTo(0,0)
    //   }
    // } )
  }

  showFooter: boolean = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.titleService.setTitle(this.translate.instant('channel.title'));
    }, 1000);
    this.actRoute.data.subscribe((data: any) => {
      this.getBanner();
      this.getCategoriesList();
      this.getArchieveData();
      this.getLiveVideos();
      this.getAlertNotification();
      this.getVideoCategoriesList();
      this.getData()
      this.getMultiLiveEvents();
    });
    if (this._iOSDevice) {
      this.playStoreUrl = this.sanitizer.bypassSecurityTrustUrl('https://itunes.apple.com/app/id1666144058');
    }
  }

  openLink() {
    // window.location = this.playStoreUrl
  }

  getBanner() {
    // return this.homeServiceService.getBanner();
    this.homeService
      .getBanner()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.bannerList = res.data;
      });
  }

  getSidebarList() {
    this.sidebar
      .getCategoriesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.sidebardata = res.data;
      });
  }

  getArchieveData() {
    this.archieve
      .getArchives(1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.archiveData = res.data;
      });
  }

  getMultiLiveEvents() {
    this.archieve
      .getMultiLiveEvents(1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.multiLiveEvents = res.data;
      });
  }

  getLiveVideos() {
    this.archieve
      .getLives()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.liveData = res.data;
      });
  }

  getCategoriesList() {
    this.homeService
      .getCategoriesWithSubCategory()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.categoriesList = res.data;
        this.loading = false
      });
  }

  data:any = {};
  getData(): any {
    this.homeService.getCategoriesWithChildrenAndVideos().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next:(res) =>{
       this.data = res.data
      }
    })
  }


  getVideoCategoriesList() {
    this.homeService
      .getCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.videoCategoriesList = res.data;
        this.loading = false
      });
  }

  getAlertNotification() {
    this.homeService
      .getAlert()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.alert = res.title;
        },
        (err: any) => {
          this.alert = '';
        }
      );
  }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    mobileFirst: true,
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: '350px',
    cssEase: 'linear',
    zIndex: 999,
    adaptiveHeight: false,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: '350px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          mobileFirst: false,
          dots: true,
          speed: 500,
          centerMode: true,
          centerPadding: '0px',
          cssEase: 'linear',
          zIndex: 999,
          adaptiveHeight: false,
          swipe: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          mobileFirst: false,
          dots: true,
          // infinite: true,
          speed: 500,
          centerMode: true,
          centerPadding: '0px',
          cssEase: 'linear',
          zIndex: 999,
          adaptiveHeight: false,
          swipe: true,
        },
      },
      {
        breakpoint: 250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          mobileFirst: false,
          dots: true,
          // infinite: true,
          speed: 500,
          centerMode: true,
          centerPadding: '0px',
          cssEase: 'linear',
          zIndex: 999,
          adaptiveHeight: false,
          swipe: true,
        },
      },
    ],
  };

  channelslideConfig = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 4,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  slickInit(e: any) { }

  breakpoint(e: any) { }

  afterChange(e: any) { }

  beforeChange(e: any) { }
  openCategory() { }

  hideFooter() {
    this.showFooter = false;
  }
}
