import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  @Input() videoData: any = {};
  @Input() current_machine_id: any;
  @Input() multiLiveEvents:any;
  @Input() isOpenTab:any;
  @Output() childEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeStream(data: any) {
    this.childEvent.emit(data);
  }


  updateEvents(){
    this.childEvent.emit({update: true});
  }


}
