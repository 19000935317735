import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { Banner, Category } from 'src/app/shared/models/video.model';
@Injectable({
  providedIn: 'root',
})
export class HomeserviceService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getBanner(): Observable<Banner[]> {
    return this.http.get<Banner[]>(this.baseUrl + 'banners');
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'categories/videos/').pipe(
      map((data: any) => {
        data['data'].map((category: any) => {
          category.videos.map((data: any) => {
            if (data['title'].length > 50) {
              data['title'] = data['title'].substring(0, 50) + '...';
            }
            return data;
          });
        });
        return data;
      })
    );
  }


  getCategoriesWithSubCategory(): Observable<Category[]> {
    return this.http.get<Category[]>(this.baseUrl + 'categories/children');
  }

  getAlert(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'alert-message');
  }


  getCategoriesWithChildrenAndVideos(): Observable<any> {
    return this.http.get(environment.apiV2Url + 'categories/videos/').pipe(
      map((data: any) => {
        data['data']['data'].map((category: any) => {
          category.videos.map((data: any) => {
            if (data['title'].length > 50) {
              data['title'] = data['title'].substring(0, 50) + '...';
            }
            return data;
          });
        });
        return data;
      })
    );
  }
}
