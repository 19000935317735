<app-header></app-header>

<div class="wrapper">
  <div class="sidebar-main" >
    <app-sidebar></app-sidebar>
  </div>

  <div class="content">
    <router-outlet (activate)="onActivate($event)" ></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
