import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  currentLang: string;

  constructor() {
    if (!this.getCurrentLanguage()) {
      localStorage.setItem('lang', 'en')
      if (window.navigator.language == 'ja' || window.navigator.language == 'ja-JP') {
        localStorage.setItem('lang', 'jp')
      }
    }
    this.currentLang = localStorage.getItem('lang') ?? 'jp';
    this.updateHtmlLangAttr(this.currentLang);
  }

  getCurrentLanguage() {
    return localStorage.getItem('lang');
  }

  setLang(lang: string) {
    this.currentLang = lang;
    localStorage.setItem('lang', lang);
    this.updateHtmlLangAttr(lang);
  }

  getLang() {
    return this.currentLang ?? localStorage.getItem('lang');
  }

  updateHtmlLangAttr(ln: string): void {
    document.documentElement.lang = ln;
  }
}
