import { Component, HostListener, OnInit } from '@angular/core';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { Observable, of } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css'],
})
export class SearchresultComponent implements OnInit {
  public searchTitle: any;
  public items$: Observable<any> = of({});
  public searchError: any;
  public lang: any = '';
  public searchData: any = [];
  page: any = 1;
  data: any;
  meta: any = {
    current_page: 1,
    last_page: 1,
  };
  public total_pages: any = 0;
  nextPage: any;
  currentPosition = window.innerHeight;
  public loading: any = false;
  // public lang: any = '';
  public error: any = false;
  constructor(
    private datashareService: DataShareService,
    private activeRoute: ActivatedRoute,
    private searchService: SearchService,
    private translate: TranslateService,
    private langSerivce: LanguageService,
    private titleService: Title
  ) {
    this.lang = this.langSerivce.getLang();
    translate.setDefaultLang(this.lang);
  }
  @HostListener('window:scroll', ['$event.target']) // for window scroll events
  scroll(e: any) {
    if ($(window).scrollTop() + $(window).height()+30 >= $(document).height()) {
      if (this.meta.current_page < this.total_pages) {
        this.meta.current_page++;

        this.activeRoute.queryParams.subscribe((params) => {
          this.searchTitle = params['search'];
          this.getVideos(params['search']);
        });
      } else {
        // this.loading = false;
      }
    }
  }

  @HostListener('window:touchmove', ['$event.target']) // for window scroll events
  touchmove(e: any) {
    if ($(window).scrollTop() + $(window).height()+30 >= $(document).height()) {
      if (this.meta.current_page < this.total_pages) {
        this.meta.current_page++;

        this.activeRoute.queryParams.subscribe((params) => {
          this.searchTitle = params['search'];
          this.getVideos(params['search']);
        });
      } else {
        // this.loading = false;
      }
    }
  }


  ngOnInit(): void {
    this.datashareService.currentsearchInput.subscribe(data => {
        if(data){
          this.searchData = []
        }
      })
    this.translate.get('searchresult.title').subscribe((data) => {
      this.titleService.setTitle(data);
    });
    this.activeRoute.queryParams.subscribe((params) => {
      this.searchTitle = params['search'];
      this.getVideos(params['search']);
    });
  }

  getVideos(searchText: any) {
    this.loading = true;
    this.searchService
      .getSearch(searchText, this.meta.current_page)
      .subscribe((res: any) => {
        this.loading = false;
        if (res.meta.total == 0) {
          this.error = true;
          // this.loading = false;
        } else {
          this.error = false;
          // this.loading = false;
          this.meta = res.meta;
          this.nextPage = res.links.next;
          if (this.searchData.length) {
            res.data.forEach((element: any) => {
              this.searchData.push(element);
            });
          } else {
            this.searchData = res.data;
            this.total_pages = res.meta.last_page;
          }

          this.loading = false;

        }
      });
  }
}
