import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';
import { ChannelService } from 'src/app/services/channelservice/channel.service';
import { LanguageService } from 'src/app/services/language.service';
import { Category } from 'src/app/shared/models/video.model';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';


declare var $: any;
@Component({
  selector: 'app-parent-channel',
  templateUrl: './parent-channel.component.html',
  styleUrls: ['./parent-channel.component.css']
})
export class ParentChannelComponent implements OnInit {
  public id: any;
  public channelData$: Observable<any> = of({});
  channelData: any = { videos: { data: [] }, coupons: [] };
  public error: any = false;
  allData: any = []
  public lang: any = '';
  public total_pages: any = 0;
  nextPage: any;
  currentPosition = window.innerHeight;
  public loading: boolean = false;
  meta: any = {
    current_page: 1,
    last_page: 1,
  };
  constructor(
    private route: ActivatedRoute,
    private channelService: ChannelService,
    private service: DataShareService,
    public translate: TranslateService,
    private langSerivce: LanguageService,
    private titleService: Title
  ) {
    this.lang = this.langSerivce.getLang();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.onloadMore(this.id)
    })
  }

  onloadMore(id: any) {
    this.channelService
      .getChildCategory(id)
      .subscribe((res: {data: Category}) => {
        this.channelData = res.data;
        this.loading = true
        this.error=false
      },error=>{
        this.loading = true
        this.error=true
      });
  }

}
