import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  constructor(
    private titleService: Title,
    public translate: TranslateService,
    public langSerivce: LanguageService
  ) {
    
  }

  ngOnInit(): void {
    this.translate.get('terms.title').subscribe(data=>{
      this.titleService.setTitle(data);
    })
    // this.titleService.setTitle(this.translate.instant('terms.title'));
  }
}
