import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/auth/service/auth-service.service';
@Injectable({
  providedIn: 'root',
})
export class GuardService {
  constructor(private router: Router, private authService: AuthServiceService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean{
    if (this.authService.getStorageToken()) {
      return true;
    }

    // navigate to login page
    this.router.navigate(['/auth/login']);
    return false;
  }
}
