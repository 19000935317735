<a
  [class]="item.purchase_type"
  [ngClass]="{
    'tag status premium': item.purchase_type == 'premium',
    'tag status standard ': item.purchase_type == 'standard'
  }"
  [routerLink]="['/' + link]"
  [queryParams]="{ watch_id: item.id, machine_id: item.machine_id }"
>
  <div style="cursor: pointer; position: relative">
    <img class="channel-img" src="{{ item.icon_img }}" alt="{{ item.title }}" />
    <div class="description">
      <div class="textdate">
        <div class="tags">
          <div style="margin-right: 3px; " *ngIf="item.show_tag" class="tag">
            <img src="{{ item.show_tag }}" alt="tags" />
          </div>
          <div style="margin-right: 3px; " *ngIf="item.live_tag" class="live-tag">
            <img src="{{ item.live_tag }}" alt="" />
          </div>
          <div *ngIf="item.ppv_tag" class="live-tag">
            <img src="{{ item.ppv_tag }}" alt="" />
          </div>
          <div></div>
        </div>
        <p class="text">{{ item.title }}</p>
        <p *ngIf="link == 'watch'" class="date">
          {{ item.date | date : "yyyy-MM-dd" }}
        </p>
        <p *ngIf="link == 'live-watch'" class="date">
          {{ item.start_time | date : "yyyy-MM-dd" }}
        </p>
      </div>
    </div>
  </div>
</a>
