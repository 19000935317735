<nav class="navbar navbar-light bg-light header-section">
  <div class="menu-bar" style="display: flex; align-items: center">
    <span
      id="toggle-icon"
      *ngIf="toggleMenustatus"
      (click)="handleClick()"
      class="material-icons"
      >menu</span
    >
    <div (click)="ontop()">
      <a [routerLink]="['/']">
        <img id="logo" src="../../../../assets/logo/logo.png" alt="logo" />
      </a>
    </div>
  </div>
  <div class="right">
    <div class="search-bar" id="search-bar">
      <div [formGroup]="searchForm" class="input-group">
        <input
          type="search"
          id="input"
          class="form-control"
          aria-label="Search"
          aria-describedby="search-addon"
          formControlName="search"
          (keydown)="toggleMenu()"
          (keypress)="showMenu()"
          (keyup.enter)="search()"
        />

        <span class="input-group-text border-0" id="search-addon">
          <i (click)="search()" class="fas fa-search"></i>
        </span>
      </div>
      <div
        clickOutside
        (clickOutside)="clickedOutsidesearch()"
        *ngIf="searchbar"
        class="search-dropdown"
      >
        <p class="search-title">{{ "header.top_search" | translate }}</p>
        <div class="list-group-item" *ngFor="let item of topSearchList">
          <p class="search-list" (click)="chooseText(item.keyword)">
            {{ item.keyword }}
          </p>
        </div>
        <hr *ngIf="recentsearchList.length > 0" />
        <div *ngIf="recentsearchList.length > 0">
          <p class="search-title">{{ "header.recent_search" | translate }}</p>
          <div
            class="list-group-item recent-search"
            *ngFor="let item of recentsearchList"
          >
            <p (click)="chooseText(item.keyword)" class="search-list">
              {{ item.keyword }}
            </p>
            &nbsp;&nbsp;&nbsp;
            <span (click)="deleteHistory(item.id)" class="material-icons">
              clear
            </span>
          </div>
        </div>
      </div>
    </div>
    <div (click)="handleSearch()" class="search">
      <span class="material-icons"> search </span>
    </div>
    <div clickOutside (clickOutside)="clickedOutside()">
      <div
        *ngIf="!authService.isLoggedIn()"
        (click)="handleProfile($event)"
        class="profile-icon"
        id="profile-icon"
       
      >
        <span class="material-icons"> account_circle </span>
      </div>
      <div
        *ngIf="authService.isLoggedIn()"
        (click)="handleProfile($event)"
        class="profile-icon loggedin"
        id="profile-icon"
        
      >
        <span class="material-icons"> account_circle </span>
      </div>
      <div *ngIf="profile" class="profile-dropdown">
        <div *ngIf="!authService.isLoggedIn()">
          <div (click)="onLogin()" class="menu">
            <div class="menu-item">
              <span class="material-icons"> account_circle </span>
              <a class="dropdown-item">{{ "header.login" | translate }}</a>
            </div>
          </div>
          <div>
            <div
              (click)="handleLanguage()"
              [ngClass]="
                languageState ? 'menu language active' : 'menu language'
              "
            >
              <div class="menu-item">
                <div>
                  <span class="material-icons translate-icon"> translate </span>
                  <span class="lang">{{ "header.language" | translate }}</span>
                </div>
                <div>
                  <span class="material-icons"> expand_more </span>
                </div>
                <!-- <span *ngIf="languageState" class="material-icons">
                  expand_less
                </span> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="authService.isLoggedIn()">
          <div (click)="onMypage()" class="menu">
            <div class="menu-item">
              <span class="material-icons"> person_outline </span>
              <a class="dropdown-item">{{ "header.my_page" | translate }}</a>
            </div>
          </div>
          <div (click)="onLogout()" class="menu">
            <div class="menu-item">
              <span class="material-icons">logout</span>
              <a class="dropdown-item">{{ "header.logout" | translate }}</a>
            </div>
          </div>
          <div
            (click)="handleLanguage()"
            [ngClass]="languageState ? 'menu language active' : 'menu language'"
          >
            <div class="menu-item">
              <div>
                <span class="material-icons translate-icon">translate</span>
                <span class="lang">{{ "header.language" | translate }}</span>
              </div>
              <i class="far fa-angle-down"></i>
            </div>
          </div>
        </div>

        <div *ngIf="languageState" class="sub-menu hidden">
          <div
            (click)="changeLanguage('en')"
            [ngClass]="selectedLanguage == 'en' ? 'item active' : 'item'"
          >
            <span
              [ngClass]="
                selectedLanguage == 'en'
                  ? 'material-icons active'
                  : ' material-icons inactive'
              "
              >done</span
            >
            <a class="dropdown-item">English</a>
          </div>
          <div
            (click)="changeLanguage('jp')"
            [ngClass]="selectedLanguage == 'jp' ? 'item active' : 'item'"
          >
            <span
              [ngClass]="
                selectedLanguage == 'jp'
                  ? 'material-icons active'
                  : ' material-icons inactive'
              "
              >done</span
            >
            <a class="dropdown-item">日本</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<div *ngIf="searchView" class="m-search-view d-block">
  <div class="mt-1 mb-2 c-bar">
    <button (click)="searchClose()" type="button" class="close">
      <span class="material-icons">close</span>
    </button>
  </div>
  <form [formGroup]="searchForm">
    <div
      _ngcontent-xii-c60=""
      class="input-group ng-pristine ng-invalid ng-touched"
      ng-reflect-form="[object Object]"
    >
      <input
        _ngcontent-xii-c60=""
        type="search"
        enterkeyhint="search"
        id="input"
        class="form-control"
        aria-label="Search"
        aria-describedby="search-addon"
        formControlName="search"
        (keyup.enter)="search()"
      />
      <span
        _ngcontent-xii-c60=""
        id="search-addon"
        class="input-group-text border-0"
      >
        <i (click)="search()" _ngcontent-xii-c60="" class="fas fa-search"></i>
      </span>
    </div>
  </form>
  <div class="filters mt-4">
    <h2>人気のワード</h2>
    <div>
      <a
        *ngFor="let item of topSearchList"
        (click)="chooseText2(item.keyword)"
        >{{ item.keyword }}</a
      >
    </div>
  </div>
  <div *ngIf="recentsearchList.length > 0" class="s-list mt-4">
    <h2>検索履歴</h2>
    <div *ngFor="let item of recentsearchList">
      <span (click)="chooseText2(item.keyword)">{{ item.keyword }}</span>
      <a
        ><span (click)="deleteHistory(item.id)" class="material-icons"
          >close</span
        ></a
      >
    </div>
  </div>
</div>
