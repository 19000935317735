import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language.service';
import { DataShareService } from './utilitis/services/data-share-services';
import { Firestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  status: any;
  item$: Observable<any>;
  firestore: Firestore;
  mobilesidebar: any;
  title = 'DartsLivetv';
  public lang: any = '';
  constructor(
    private datashareService: DataShareService,
    private router: Router,
    private titleService: Title,
    private message: AngularFireMessaging,
    private meta: Meta,
    private langSerivce: LanguageService
  ) {
    this.datashareService.changeprofileStatus(false);
    this.lang = this.langSerivce.getLang();
    if (environment.production && environment.apiUrl=="https://api.live.dartslive.tv/api/v1/") {
      if (this.lang == 'en') {
        this.meta.addTags([
          {
            'data-n-head': 'description',
            name: 'description',
            content: 'Fascinating dart fans with high level matches',
          },
          {
            'data-n-head': 'og:description',
            name: 'og:description',
            content: 'Fascinating dart fans with high level matches',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'keywords',
            content:
              'dartslive,ダーツライブ,DARTS,ダーツ,飛鏢,ダーツライブtv,dartslive tv,ダーツ 投げ方,ダーツ 試合',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:type',
            name: 'og:type',
            content: 'og:type',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:url',
            name: 'og:url',
            content: 'https://dartslive.tv/',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:site_name',
            name: 'og:site_name',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:i18n-og',
            name: 'og:locale',
            content: 'en_US',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'i18n-og-alt-ja-JP',
            name: 'og:locale:alternate',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content: 'DARTSLIVE TV - DARTSLIVE Official Video Site',
          },
        ]);
      }
      if (this.lang == 'jp') {
        this.meta.addTags([
          {
            'data-n-head': 'description',
            name: 'description',
            content: '世界のダーツファンを魅了するハイレベルな試合を配信',
          },
          {
            'data-n-head': 'og:description',
            name: 'og:description',
            content: '世界のダーツファンを魅了するハイレベルな試合を配信',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            'data-n-head': 'ssr',
            name: 'keywords',
            content:
              'dartslive,ダーツライブ,DARTS,ダーツ,飛鏢,ダーツライブtv,dartslive tv,ダーツ 投げ方,ダーツ 試合',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:type',
            name: 'og:type',
            content: 'og:type',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:url',
            name: 'og:url',
            content: 'https://dartslive.tv/',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:site_name',
            name: 'og:site_name',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:i18n-og',
            name: 'og:locale',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'i18n-og-alt-ja-JP',
            name: 'og:locale:alternate',
            content: 'ja_JP',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
          {
            'data-n-head': 'ssr',
            'data-hid': 'og:title',
            name: 'og:title',
            content:
              'ダーツライブTV - DARTSLIVE(ダーツライブ)公式ダーツ動画サイト',
          },
        ]);
      }
    }
  }
  @HostListener('window:storage')
  onStorageChange() { }

  ngOnInit(): void {
    this.requestPermission();
    this.titleService.setTitle(this.title);
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.datashareService.sidebarStatus.subscribe((res) => {
      this.status = res;
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
          console.log('Service Worker registration failed:', err);
        });
    }
  }

  onActivate(event: any) {
    if (event.constructor.name == '/') {
      window.scrollTo(0, 0);
    }
  }

  requestPermission() {
    this.message.requestPermission.subscribe(
      () => {
        this.message.getToken.subscribe((token: any) => {
          this.subscribeToTopic('/topics/dev-stream-dev', token);
        });
      },
      (error) => {
        console.log('Permission denied:', error);
      }
    );
  }

  subscribeToTopic(topic: string, token: string) {
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel${topic}`, {
			method: 'POST',
			headers: new Headers({
				Authorization: `key=AAAAnbKtCzM:APA91bEnre-BnoeqsyLcjXWac69M0m0FWCIgryeeOuFaWMhYeIhax53RRor_HDof0pV6D4SzdcCVa_OsOEsh2PlE3tT35lmyE1BMCEMoviY6NB3jXnNa8Xdi2OHQnzR8SsMFN2tvkcdl`
			})
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 400) {
			}
		})
		.catch((error) => {
		});
		return true;
  }

  listenForMessages() {
    this.message.messages.subscribe((message) => {
    });
  }

}
