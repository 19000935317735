<div class="vid-box text-center">
    <img [src]="videoData.cover_image" />
    <div class="vid-overlay" *ngIf="!videoData.restrict">
      <div class="login-modal text-center">
        <p>{{ "login.loginModal" | translate }}</p>
        <div class="login">
          <button
            [routerLink]="['/auth/login']"
            type="button"
            class="btn btn-primary"
          >
            {{ "login.loginButton" | translate }}
          </button>
        </div>

        <div class="forgetpassword">
          <a
            *ngIf="lang == 'jp'"
            href="https://dlapp.zendesk.com/hc/ja/articles/360057861933"
            target="_blank"
          >
            > &nbsp;{{ "login.forgetpassword" | translate }}</a
          >
          <a
            *ngIf="lang == 'en'"
            href="https://dlapp.zendesk.com/hc/en-us/articles/360057861933"
            target="_blank"
          >
            > &nbsp;{{ "login.forgetpassword" | translate }}</a
          >
        </div>
        <div class="register" (click)="openLink()">
          <a target="_blank">
            <button type="button" class="btn btn-primary">
              {{ "login.registerButton" | translate }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>