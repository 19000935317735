<div class="tab-content" id="pills-tabContent">
  <div
    class="tab-pane fade show"
    [ngClass]="{ active: isOpenTab == 'Overview' }"
    id="pills-home"
    role="tabpanel"
    aria-labelledby="pills-home-tab"
    tabindex="0"
  >
    <div class="title">
      <div>
        <p>{{ videoData.title }}</p>
      </div>
    </div>
    <div class="categories-title">
      <p>{{ videoData.category.title_en }}</p>
    </div>
    <div class="description" id="description">
      <div class="link" [innerHTML]="videoData.description"></div>
    </div>
  </div>
  <div
    class="tab-pane fade show"
    [ngClass]="{ active: isOpenTab == 'multilive' }"
    id="pills-profile"
    role="tabpanel"
    aria-labelledby="pills-profile-tab"
    tabindex="0"
  >
    <div>
      <h2 class="text-left">
        Matches in Live Streaming
        <button (click)="updateEvents()" style="border: none; background: none;"><i class="fa fa-refresh"></i></button>
      </h2>
    </div>
    <div class="row" *ngIf="videoData.has_multi_channel">
      <div
        class="col-6 col-sm-6 col-md-3"
        style="cursor: pointer"
        *ngFor="let data of multiLiveEvents"
        (click)="changeStream(data)"
      >
        <div class="text-center">
          <img
            [src]="data.thumbnail"
            [ngClass]="{
              'active-class': current_machine_id == data.machine_id
            }"
            [alt]="data.title"
            width="100%"
          />
          <p
            class="text-center"
            style="padding: 3px; font-weight: 500"
            *ngIf="data.cards"
          >
            {{ data.cards }}
          </p>
          <p
            class="text-center"
            style="padding: 3px; font-weight: 500"
            *ngIf="!data.cards"
          >
            Main Channel
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
