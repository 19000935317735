<div class="row">
  <div class="col-sm-10 offset-sm-1 col-12">
    <div class="youtube-section">
      <div class="title">
        <p>YouTubeで人気</p>
      </div>
      <div class="row list">
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="row list">
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="row list">
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="row list">
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-lg-3 col-md-6">
          <iframe
            src="https://www.youtube.com/embed/cuVAYBES3Mk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
