import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getSearch(search: any, page: any) {
    return this.http
      .get(this.baseUrl + 'video/search?query_string=' + search + '&page=' + page + '&per_page=50')
      .pipe(
        map((data: any) => {
          data['data'].map((video: any) => {
            if (video['title'].length > 50) {
              video['title'] = video['title'].substring(0, 50) + '...';
            }
            if (video['title'].length > 18 && window.screen.width <= 400) {
              video['title'] = video['title'].substring(0, 18) + '...';
            }
            return video;
          });
          return data;
        })
      );
  }

  // recordsearchHistory(key_word: any) {
  //   let headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + localStorage.getItem('a_token') || '',
  //   });
  //   let options = { headers: headers };
  //   return this.http.post(
  //     this.baseUrl + 'search-history',
  //     { keyword: key_word },
  //     options
  //   );
  // }

  getsearchHistory() {
    return this.http.get(this.baseUrl + 'history/search');
  }

  deletesearchHistory(id: any) {
    return this.http.post(
      this.baseUrl + 'history/search/' + id + '/delete',
      {}
    );
  }
}
