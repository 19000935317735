<ng-container *ngIf="loading">
  <div class="channel-section">
    <div class="row">
      <div class="col-sm-10 offset-sm-1 col-12">
        <div class="channel-title">
          <h1>{{ channelData.title }}</h1>
        </div>
        <div>
          <div style="padding:10px;" [innerHTML]="channelData.description"></div>
        </div>
        <div *ngIf="channelData.coupons.length > 0" class="channel-logo">
          <div *ngFor="let coupon of channelData.coupons" id="channellogo">
            <a target="_blank" href="{{ coupon.link }}">
              <img [src]="coupon.image" alt="" />
            </a>
          </div>
        </div>

        <!-- <ng-container *ngFor="let item of channelData.live_events; let i = index">
          <div *ngIf="i == 0">
            <a
              [ngClass]="{
                'tag status premium': item.purchase_type == 'premium',
                'tag status standard ': item.purchase_type == 'standard'
              }"
              style="text-decoration: none; color: black"
              [routerLink]="['/live-watch']"
              [queryParams]="{ watch_id: item.id }"
            >
              <div class="live" id="live-section">
                <div>
                  <img
                    [src]="item.icon_img"
                    id="channel-thumbnail"
                    [alt]="item.title"
                  />
                </div>

                <div class="description" id="description">
                  <div class="tags">
                    <div
                      style="margin-right: 5px"
                      *ngIf="item.show_tag"
                      class="c-tag"
                    >
                      <img src="{{ item.show_tag }}" alt="tags" class="dtag" />
                    </div>
                    <div *ngIf="item.live_tag" class="live-tag">
                      <img src="{{ item.live_tag }}" alt="tags" class="dtag" />
                    </div>
                  </div>
                  <div class="title">
                    <h3>{{ item.title }}</h3>
                    <span> {{ item.start_time | date : "yyyy-MM-dd" }}</span>
                  </div>
                  <div
                    class="link d-lg-block d-sm-none"
                    [innerHTML]="item.description"
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </ng-container> -->

        <ng-container *ngFor="let item of allData; let i = index">
          <div *ngIf="i == 0">
            <a [ngClass]="{
                'tag status premium': item.purchase_type == 'premium',
                'tag status standard ': item.purchase_type == 'standard'
              }" style="text-decoration: none; color: black" [routerLink]="['/', item.link]"
              [queryParams]="{ watch_id: item.id }">
              <div class="live" id="live-section">
                <div>
                  <img [src]="item.icon_img" id="channel-thumbnail" [alt]="item.title" />
                </div>

                <div class="description" id="description">
                  <div class="tags">
                    <div style="margin-right: 5px" *ngIf="item.show_tag" class="c-tag">
                      <img src="{{ item.show_tag }}" alt="tags" class="dtag" />
                    </div>
                    <div *ngIf="item.live_tag" class="live-tag">
                      <img src="{{ item.live_tag }}" alt="tags" class="dtag" />
                    </div>
                  </div>
                  <div class="title">
                    <h3>{{ item.title }}</h3>
                    <span *ngIf="item.type == 'video'" style="color: #818181">
                      {{ item.date | date : "yyyy-MM-dd" }}</span>
                    <span *ngIf="item.type == 'live'" style="color: red">
                      {{ item.start_time | date : "yyyy-MM-dd" }}</span>
                  </div>
                  <div class="link d-lg-block d-sm-none" [innerHTML]="item.description"></div>
                </div>
              </div>
            </a>
          </div>
        </ng-container>

        <hr />

        <div class="archive" id="archivesection">
          <div class="row gap-0">
            <!-- <ng-container
              *ngFor="let item of channelData.live_events; let i = index"
            >
              <div *ngIf="i != 0" class="col-lg-3 col-md-6 col-sm-12 padding-0">
                <a
                  [ngClass]="{
                    'tag status premium': item.purchase_type == 'premium',
                    'tag status standard ': item.purchase_type == 'standard'
                  }"
                  style="text-decoration: none; color: black"
                  [routerLink]="['/', item.link]"
                  [queryParams]="{ watch_id: item.id }"
                >
                  <div class="list">
                    <div class="items">
                      <div>
                        <img
                          class="archive-thumbnail"
                          [src]="item.icon_img"
                          alt=""
                        />
                      </div>

                      <div class="archive-des">
                        <div class="wtags">
                          <div
                            style="margin-right: 5px"
                            *ngIf="item.show_tag"
                            class="c-tag"
                          >
                            <img src="{{ item.show_tag }}" alt="tags" />
                          </div>
                          <div *ngIf="item.live_tag" class="live-tag">
                            <img src="{{ item.live_tag }}" alt="" />
                          </div>
                        </div>
                        <p class="des-title">{{ item.title }}</p>
                        <p class="date">
                          {{ item.start_time | date : "yyyy-MM-dd" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container> -->
            <ng-container *ngFor="let item of allData; let i = index">
              <div class="col-lg-3 col-md-6 col-sm-12 padding-0" *ngIf="i != 0">
                <a [ngClass]="{
                    'tag status premium': item.purchase_type == 'premium',
                    'tag status standard ': item.purchase_type == 'standard'
                  }" style="text-decoration: none; color: black" [routerLink]="['/', item.link]"
                  [queryParams]="{ watch_id: item.id }">
                  <div class="list">
                    <div class="items">
                      <div style="position: relative">
                        <img class="archive-thumbnail" [src]="item.icon_img" alt="" />
                      </div>

                      <div class="archive-des">
                        <div class="wtags">
                          <div style="margin-right: 5px" *ngIf="item.show_tag" class="c-tag">
                            <img src="{{ item.show_tag }}" alt="tags" />
                          </div>
                          <div *ngIf="item.live_tag" class="live-tag">
                            <img src="{{ item.live_tag }}" alt="tags" class="dtag" />
                          </div>
                        </div>
                        <p class="des-title">{{ item.title }}</p>
                        <p class="date" [ngClass]="{ 'live-red': item.type == 'live' }">
                          <span *ngIf="item.type == 'video'" style="color: #818181">
                            {{ item.date | date : "yyyy-MM-dd" }}</span>
                          <span *ngIf="item.type == 'live'" style="color: red">
                            {{ item.start_time | date : "yyyy-MM-dd" }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="channel-section">
    <div class="row">
      <div class="col-sm-10 offset-sm-1 col-12">
        <app-loader></app-loader>
      </div>
    </div>
  </div>

</ng-container>