<div class="v-main__wrap">
    <ng-container *ngIf="langSerivce.getLang()=='en'">
        <div data-v-459cfd84="" data-v-edfbd00c="" class="row pageBackground fill-height no-gutters">
            <div class="col-sm-10 offset-sm-1 col-12">
                <h2 data-v-459cfd84="" class="text-sm-h4 text-h6  px-8 text-center">
                    Terms of Use
                </h2>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 1 </span>
                        Definition
                    </h3>
                    <p data-v-22f0b0fe="" class="mb-0">The term “we”, “us” or “our” means DARTSLIVE Co., Ltd.</p>
                    <p data-v-22f0b0fe="" class="mb-0">“DARTSLIVE” means the dart machine(s) manufactured and provided
                        by
                        DARTSLIVE
                        Co., Ltd.</p>
                    <p data-v-22f0b0fe="" class="mb-0">“Service” means the online services we provide regarding
                        DARTSLIVE,
                        such as;
                        social gaming, information browsing from PCs, smartphones and mobile phones. “Free Service”means
                        the
                        Service
                        which is free of charge and “Paid Service” means the Service which is subject to fees.</p>
                    <p data-v-22f0b0fe="" class="mb-0">“Content” means all the information you post via the Service
                        including text,
                        graphics, images or other materials.</p>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 2 </span>
                        Service Agreement
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">By accessing or using the Service, you represent and warrant that you
                            have
                            read,
                            understood and agreed to these Terms of Use, and before using the Service you shall enter
                            into a
                            service
                            agreement with us (“Agreement”).</li>
                        <li data-v-22f0b0fe="">You enter into the Agreement on your own will.</li>
                        <li data-v-22f0b0fe="">If you are a minor, the Agreement requires consent from your legal
                            guardian
                            (any
                            person with parental authority).</li>
                        <li data-v-22f0b0fe="">The Service is not available for members and former members of antisocial
                            forces and
                            their related people.</li>
                        <li data-v-22f0b0fe="">You shall be deemed to agree to these Terms of Use once you use the
                            Service.
                        </li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 3 </span>
                        Notes on Use
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">
                            Service in General
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">You shall use the Service at your own responsibility and are
                                    responsible for
                                    any consequences generated by the use of it.</li>
                                <li data-v-22f0b0fe="">You are responsible to prepare equipment that is required in
                                    order to
                                    use the
                                    Service at your own expense and at your own responsibility.</li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">
                            Use and Management of Email Address and Password
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">In order to use the Service, you need to set up your account
                                    using
                                    your email
                                    account and a password of your choice. You may purchase a dedicated IC card and
                                    register
                                    the
                                    card with your account by entering the DARTSLIVE card ID (“ID”) on the IC card.</li>
                                <li data-v-22f0b0fe="">You must control your ID and password strictly, and must not let
                                    any
                                    third
                                    party use your email address and password. You must be responsible for the use of
                                    the
                                    Service
                                    with your email address and password. We will not be held responsible for any damage
                                    caused to
                                    you in case your email address and the password you safeguard is used by a third
                                    party.
                                </li>
                                <li data-v-22f0b0fe="">You may delete your account and unsubscribe to the Service at any
                                    time. If
                                    you delete your account, it can not be restored later.</li>
                                <li data-v-22f0b0fe="">The Service Account belongs to you and you only. You can not
                                    transfer, rend
                                    or rent your account. No third party may exercise your right to use your account.
                                </li>
                                <li data-v-22f0b0fe=""> </li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">
                            Content
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">You declare and certify that you hold a legitimate right on the
                                    Content of
                                    yours.</li>
                                <li data-v-22f0b0fe="">You shall not send or post any inappropriate Content that is
                                    obscene,
                                    violent, defamatory, encourages hate speeches and abusive behaviors, infringes
                                    copyright, other
                                    intellectual property or other rights of any other person or which we, in our sole
                                    and
                                    absolute
                                    discretion, deems to be inappropriate.</li>
                                <li data-v-22f0b0fe="">We reserve the right to delete, restrict or limit any Content you
                                    made
                                    available in violation of the preceding item or if deemed inappropriate at our sole
                                    discretion
                                    without any prior or post notification to you. However, we will not be responsible
                                    for
                                    detection, finding, removal or limitation of any inappropriate Content of yours.
                                </li>
                                <li data-v-22f0b0fe="">We are not obligated to save any of the Content.</li>
                                <li data-v-22f0b0fe="">You are solely responsible for all Content that you make
                                    available
                                    via the
                                    Service and solely responsible to resolve troubles arising out of the Content you
                                    made
                                    available.</li>
                                <li data-v-22f0b0fe="">In addition to the above, we may review or use the Content to
                                    enhance
                                    the
                                    Service or to check the status of compliance with laws and regulations. However, we
                                    assume no
                                    obligation to conduct these actions.</li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">
                            Gameplay Video
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">You agree that the built-in camera on DARTSLIVE may record your
                                    gameplay
                                    video (“Gameplay Video”).</li>
                                <li data-v-22f0b0fe="">You agree that Gameplay Video can be viewed from your device and
                                    recorded on
                                    it. Gameplay Video can appear on the monitor of the DARTSLIVE machine you are
                                    playing.
                                    Gameplay
                                    Video can be available to the users of the Service to whom you gave permission to
                                    view.
                                    Your
                                    gameplay video can be used for the purposes described in these terms of use.
                                    However, we
                                    are not
                                    responsible to make all these functions available in the Service.</li>
                                <li data-v-22f0b0fe="">In addition to the preceding item, we can use Gameplay Video for
                                    enhancement
                                    of the Service and prevention of fraudulent acts. You agree to the above use by us.
                                </li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">
                            Privacy
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">We will handle your personal information appropriately in
                                    accordance
                                    with our
                                    Privacy Policy.</li>
                                <li data-v-22f0b0fe="">Please be aware of the extent of the disclosure if and when you
                                    post
                                    any
                                    personal information through the Service.</li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">
                            Method of Contact
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">We will make announcements regarding the Service for its users on
                                    an
                                    appropriate location on our websites, applications and other media in a method we
                                    consider as
                                    suitable.</li>
                                <li data-v-22f0b0fe="">If you would like to submit any comments or queries about the
                                    Service, you
                                    may use our contact form on our website or other methods we provide.</li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">
                            Advertisement
                            <br data-v-22f0b0fe="">
                            We may show our or third party ads on the Service.
                        </li>
                        <li data-v-22f0b0fe="">
                            Change of Contact Information
                            <br data-v-22f0b0fe="">
                            If your contact information, such as; email address or other information that are provided
                            to us
                            changes, you shall notify us promptly.
                        </li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 4 </span>
                        Ownership of Copyright and Other Rights
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">We or rightful third party owners of the materials own the intellectual
                            properties
                            and other rights and information including the copyright related to the Service. Without any
                            prior
                            consent from us or the rightful owner of the materials, you may not copy, transfer, lease,
                            lend,
                            rent,
                            distribute, alter, publish, transmit (or make the material transmittable) or publicly
                            display
                            the
                            material or information from the Service beyond the extent allowable under laws and
                            regulations.
                        </li>
                        <li data-v-22f0b0fe="">We do not grant you any right to use our trademarks and logos such as
                            “DARTSLIVE” and
                            all associated intellectual properties.</li>
                        <li data-v-22f0b0fe="">You agree to grant us the right to copy and use the Content free of
                            charge
                            without
                            any limitation of time and region.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 5 </span>
                        Service Fee and Method of Payment
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">We will provide information on the service fees of our Paid Service
                            (“Service
                            Fee(s)”) and payment methods separately.</li>
                        <li data-v-22f0b0fe="">Once you pay Service Fee or other fees, no refund is available.</li>
                        <li data-v-22f0b0fe="">No such payment methods are available when the name of the payer does not
                            match the
                            Service user.</li>
                        <li data-v-22f0b0fe="">Before using a payment method that you choose, you must read and
                            understand
                            terms and
                            conditions thereof. We are not responsible for any trouble arisen between you and your
                            settlement
                            company.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 6 </span>
                        Cancellation of Paid Service
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">Cancellation of Paid Service must be done online. You understand that you
                            are
                            not
                            able to use Paid Service from the date of cancellation.</li>
                        <li data-v-22f0b0fe="">Any online cancellation of Paid Service is deemed to be made at your own
                            will.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 7 </span>
                        Indemnification
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">We offer no guarantees about such things as the completeness, accuracy,
                            or
                            utility of
                            the Service and any information provided by the Service.</li>
                        <li data-v-22f0b0fe="">We take no responsibility for the use of any service provided by or any
                            act
                            by any
                            third party other than us.</li>
                        <li data-v-22f0b0fe="">In case we incur any liability for damages, our liability shall be
                            limited to
                            actual
                            and direct damages up to the price you paid to us as the Service Fee in the past one year,
                            calculating
                            retroactively from the time when the cause of the damage accrued. Regardless of our
                            predictability, we
                            are not liable for damage such as those resulting from special circumstances, lost profits,
                            or
                            any
                            consequential damages. Provided however, the foregoing shall not apply to the case the
                            damage
                            results
                            from our intentional acts or gross negligence.</li>
                        <li data-v-22f0b0fe="">We are not liable for any claims or damages if the Service is canceled or
                            terminated
                            due to any reasons not attributable to us.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 8 </span>
                        Change and Suspension of the Service
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">Part or all of the Service may be unavailable without prior notice for
                            network
                            preparation, system problems, regular and irregular maintenances or to prepare for any event
                            of
                            force
                            majeure including natural disaster, conflict and war, or any other case where we determine
                            that
                            the
                            Service is in need of suspension or termination. We do not guarantee that the Service is not
                            suspended
                            or terminated.</li>
                        <li data-v-22f0b0fe="">In spite of the preceding paragraph, we may change, cancel or terminate
                            the
                            Service
                            at any time with notification to users 30 days prior thereto, online or with other methods.
                            However,
                            this is not applied to any minor changes.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 9 </span>
                        Prohibited Activities
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">
                            You shall not engage in the following:
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">Any act in violation or possible violation of laws or regulations
                                </li>
                                <li data-v-22f0b0fe="">Any act offensive or may be offensive to public order and morals
                                </li>
                                <li data-v-22f0b0fe="">Any act that may infringe upon our right or any third party’s
                                </li>
                                <li data-v-22f0b0fe="">Any act to spread false or misleading information purposefully or
                                    to
                                    pretend
                                    to be us or a third party</li>
                                <li data-v-22f0b0fe="">Any act to collect, disclose or offer personal or registered
                                    information of
                                    third parties illegally</li>
                                <li data-v-22f0b0fe="">Any act of causing trouble or disturbances to the server and the
                                    network
                                    system of the Service</li>
                                <li data-v-22f0b0fe="">Any act, such as; making fake inquiries and unjust claims</li>
                                <li data-v-22f0b0fe="">Any act that might offend other users, such as; soliciting to
                                    join
                                    commercial, and non-commercial organizations including political and religious ones
                                </li>
                                <li data-v-22f0b0fe="">Any act to assist or provide profit to antisocial forces</li>
                                <li data-v-22f0b0fe="">Fraudulent acts and acts to unduly change darts game results</li>
                                <li data-v-22f0b0fe="">Any act violating to these Terms of Use</li>
                                <li data-v-22f0b0fe="">Other acts considered by us as inappropriate for the Service</li>
                            </ol>
                        </li>
                        <li data-v-22f0b0fe="">We may take necessary measures including deletion of your account,
                            removal
                            and
                            purging of the Content and fraudulent game results without any prior notice when we regard
                            that
                            as
                            inappropriate, or when one of the acts listed in the previous paragraph occurs. However, we
                            do
                            not
                            assume any obligation to remove such Content.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 10 </span>
                        Cancellation
                    </h3>
                    <p data-v-22f0b0fe="" class="mb-0">We may cancel the Agreement without any notice if you violate any
                        provision
                        of these Terms of Use.</p>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 11 </span>
                        Severability
                    </h3>
                    <p data-v-22f0b0fe="" class="mb-0">If one or more provisions of these Terms of Use or the Agreement
                        is
                        found to
                        be unlawful, void or unenforceable according to mandatory provisions of the law such as consumer
                        protection
                        law, such provision(s) shall be deemed severable and shall not affect the validity and/or
                        enforceability of
                        the remaining provisions of these Terms of Use or the Agreement, which will remain in full force
                        and
                        effect.
                    </p>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 12 </span>
                        Non-Assignability
                    </h3>
                    <p data-v-22f0b0fe="" class="mb-0">You may not assign any right, position or interest of the
                        Agreement
                        to any
                        third party.</p>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 13 </span>
                        Applicable Law and Venue
                    </h3>
                    <ol data-v-22f0b0fe="">
                        <li data-v-22f0b0fe="">These Terms of Use and the Agreement are governed by and are to be
                            construed
                            and
                            enforced under the laws of Japan, without applying any conflicts of law principles that
                            would
                            require
                            application of the law of any other jurisdiction.</li>
                        <li data-v-22f0b0fe="">All disputes arising between you and us in connection with these Terms of
                            Use
                            or the
                            Agreement are to be submitted to the exclusive jurisdiction of the Tokyo District Court or
                            Tokyo
                            Summary
                            Court in the first instance.</li>
                    </ol>
                </section>
                <section data-v-22f0b0fe="">
                    <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> Section 14 </span>
                        Modification of these Terms of Use
                    </h3>
                    <p data-v-22f0b0fe="" class="mb-0">We may add, revise, or modify these Terms of Use without any
                        prior
                        notice, to
                        reflect the change in social conditions and to better accommodate business and service change.
                        Continuous
                        use of the Service after the modification in these Terms of Use is considered as the acceptance
                        of
                        the
                        revision from you.</p>
                </section>
                <div data-v-22f0b0fe="" class="text-right">
                    Enactment: April 7, 2005 <br data-v-22f0b0fe="">
                    Revision: April 20, 2011 <br data-v-22f0b0fe="">
                    Revision: January 20, 2014 <br data-v-22f0b0fe="">
                    Revision: March 2, 2018 <br data-v-22f0b0fe="">
                    Revision: October 23, 2018 <br data-v-22f0b0fe="">
                    Revision: April 2, 2019 <br data-v-22f0b0fe="">
                    DATSLIVE Co., Ltd.
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="langSerivce.getLang()!='en'">
        <div data-v-22f0b0fe="" data-v-edfbd00c="" class="row pageBackground fill-height no-gutters">
            <div class="col-sm-10 offset-sm-1 col-12">
                <h2 data-v-22f0b0fe="" class="text-sm-h4 text-h6 px-8   text-center">
                    DARTSLIVE (ダーツライブ)サービス利用規約
                </h2>
                <div data-v-34139930="" data-v-22f0b0fe=""
                    class="contentBackground content-card body-2  v-card v-sheet theme--light rounded-0">
                    <div data-v-22f0b0fe="" class="px-6">
                        <p data-v-22f0b0fe="">この利用規約（以下「本規約」といいます）は、DARTSLIVEサービスを利用するお客様に適用されます。</p>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 1 </span>
                                定義
                            </h3>
                            <p data-v-22f0b0fe="" class="mb-0">
                                「DARTSLIVE」とは、株式会社ダーツライブ（以下「当社」といいます）が提供するダーツマシン（筐体）の名称をいいます。</p>
                            <p data-v-22f0b0fe="" class="mb-0">
                                「DARTSLIVEサービス」とは、DARTSLIVEを使用して行なう対戦プレイ、PC・スマートフォン・モバイル等の端末（以下「端末」といいます）からの情報閲覧サービスなど、当社がDARTSLIVEに関連して提供するネットワークサービスをいいます。無料で利用可能なものを「無償サービス」、有料で利用可能なものを「有料サービス」といい、両者を併せて、以下「本サービス」といいます。
                            </p>
                            <p data-v-22f0b0fe="" class="mb-0">「コンテンツ」とは、お客様が本サービスを利用して投稿、送信した文章、画像その他の一切の情報をいいます。</p>
                            <p data-v-22f0b0fe="" class="mb-0"> </p>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 2 </span>
                                利用契約
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">
                                    お客様が､本サービスを利用する場合、本規約の内容に同意のうえ、端末を通じて当社と本サービスの利用契約（以下｢本契約｣といいます）を締結するものとします。本規約にご同意いただけない場合には、本サービスを利用することはできません。
                                </li>
                                <li data-v-22f0b0fe="">本契約の締結は、全てお客様の意思で行なわれるものとします。</li>
                                <li data-v-22f0b0fe="">お客様が未成年者である場合には、必ず法定代理人（親権者等）の同意を得て、本契約をご締結ください。</li>
                                <li data-v-22f0b0fe="">反社会的勢力の構成員（過去に構成員であった場合を含みます）及びその関係者の方は本サービスをご利用になることはできません。</li>
                                <li data-v-22f0b0fe="">お客様が本サービスを利用した場合、お客様は本規約に同意したものとします。</li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 3 </span>
                                利用上の留意事項
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">
                                    サービス全般
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">
                                            お客様は、自らの責任において、本サービスを利用するものとし、これにより引き起こされる結果のすべてについて責任を負うものとします。</li>
                                        <li data-v-22f0b0fe="">お客様は、本サービスを利用するにあたり必要となる通信機器及び費用等は、自らの費用と責任で準備するものとします。
                                        </li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    メールアドレス、パスワードの使用・管理
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">
                                            お客様は、本サービスを利用するにあたりアカウントを作成する必要があります。アカウントは、お客様が、お客様自身のメールアドレスおよび任意に設定するパスワードを登録することにより作成することができます。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            お客様は、「DARTSLIVEカード」（本サービス専用のICカード）を購入し、当該「DARTSLIVEカード」記載のカードIDをお客様のアカウントに登録することができます。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            お客様は、アカウントに登録したメールアドレス及びパスワードを厳重に管理し、これらを第三者に使用させてはならないものとします。また、お客様は、アカウントに登録したDARTSLIVEカードおよびカードIDを第三者に使用させることもできないものとします。お客様が管理するメールアドレス又はパスワードに基づいてなされたアカウントの利用、ならびに、お客様のアカウントに登録されたDARTSLIVEカードおよびカードIDの利用およびその結果については全てお客様の責任といたします。お客様が管理するメールアドレス、パスワード、DARTSLIVEカード、もしくはカードIDが第三者に使用されたことによってお客様が被る損害について、当社は一切責任を負わないものとします。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            本サービスに登録したお客様は、いつでもアカウントを削除し本サービスの利用を終了することができます。アカウントを削除した場合、いかなる理由があっても復旧できませんのでご注意下さい。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            本サービスのアカウントは、お客様に一身専属的に帰属します。お客様の本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。
                                        </li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    コンテンツ
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">お客様は、自らのコンテンツにつき正当な権利を有していることを表明かつ保証します。</li>
                                        <li data-v-22f0b0fe="">
                                            お客様は、コンテンツにおいて、不適切な内容（猥褻な内容を含むもの、暴力的な内容を含むもの、ヘイトスピーチその他の誹謗・中傷を内容とするもの、著作権その他の他者権利を侵害するもの、その他当社が合理的な裁量により不適切と判断する内容をいいます）を投稿、送信してはならないものとします。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            当社は、前号により当社が不適切な内容を含むと判断したコンテンツにつき、事前の予告及び事後の通知なしにこれを削除又は表示の制限を行うことができるものとします。ただし、当社は全ての不適切なコンテンツを検知・発見し、削除又は表示の制限をする義務を負うものではありません。
                                        </li>
                                        <li data-v-22f0b0fe="">当社は、コンテンツを保存する義務はないものとします。</li>
                                        <li data-v-22f0b0fe="">
                                            お客様によるコンテンツの投稿、送信については、お客様自らの責任においてこれを行うものとし、万が一コンテンツの内容に関してトラブルが発生した場合には、お客様自らの責任で対応するものとします。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            当社は、上記のほか、DARTSLIVEサービスの向上のため、法令若しくは本規約の遵守状況の確認のために、コンテンツの内容を確認、利用できるものとします。ただし、当社はこれらを行う義務を負うものではありません。
                                        </li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    プレイ動画
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">
                                            お客様は、DARTSLIVEにおけるダーツプレイに際して、お客様のプレイを対象とする動画が筐体カメラにより撮影及び録画される場合があることに同意するものとします（以下「プレイ動画」といいます）。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            プレイ動画は、お客様の端末からの閲覧、同端末への保存、お客様がプレイするDARTSLIVEのモニター上での再生、並びにお客様により設定された範囲内の他の本サービス利用者への公開、その他本規約に定める用途にのみ用いられるものとし、お客様はこれに同意するものとします。ただし、当社はこれら全ての機能を本サービスに実装する義務を負うものではありません。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            当社は、前号のほか、プレイ動画を、本サービスの内容向上及び不正行為の防止のために確認、利用できるものとし、お客様はこれに同意するものとします。</li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    プライバシー
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">当社は、当社プライバシーポリシーに従って、お客様の個人情報を含むプライバシー情報を適切に取り扱います。</li>
                                        <li data-v-22f0b0fe="">
                                            お客様が本サービスを通じて個人情報を投稿する場合には､当該情報が開示される範囲等をご確認の上ご利用いただくものとします。</li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    連絡方法
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">
                                            本サービスに関する当社からお客様への連絡は、当社が運営するウェブサイト、アプリケーション内の適宜の場所への掲示その他、当社が適当と判断する方法により行ないます。
                                        </li>
                                        <li data-v-22f0b0fe="">
                                            本サービスに関するお客様から当社への連絡は、当社が運営するウェブサイト内の適宜の場所に設置するお問い合わせフォームの送信又は当社が指定する方法により行うものとします。
                                        </li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    広告
                                    <br data-v-22f0b0fe="">
                                    当社は、本サービス上に、当社又は第三者の広告を掲載することができるものとします。
                                </li>
                                <li data-v-22f0b0fe="">
                                    届出事項の変更
                                    <br data-v-22f0b0fe="">
                                    お客様のメールアドレスその他の届出事項に変更のあった場合、当社所定の方法により、速やかに当社に届け出るものとします。
                                </li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 4 </span>
                                著作権等の帰属
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">
                                    本サービスに係る著作権を含む知的財産権及びその他の権利を含む情報は、当社又は情報提供者をはじめとする第三者（以下「情報提供者等」といいます）に帰属します。当社又は情報提供者等が事前に承諾した場合を除き、お客様は、これらの情報を複製、譲渡、貸与、頒布、出版、公衆送信（送信可能化を含みます）、翻案その他法令により認められる範囲を超えて使用することはできないものとします。
                                </li>
                                <li data-v-22f0b0fe="">
                                    当社は、お客様に対して、「DARTSLIVE」、その他本サービスに関し当社が所有する商標、ロゴを使用する権利を与えるものではありません。</li>
                                <li data-v-22f0b0fe="">
                                    お客様は、当社が本サービスを提供するためのコンテンツ利用権、複製権を当社に対して時間的、地理的な制限なく無償で許諾するものとします。</li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 5 </span>
                                利用料及び決済方法
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">有料サービスの利用料（以下「利用料」といいます）、支払時期及び決済方法は、当社から別途提示するものとします。</li>
                                <li data-v-22f0b0fe="">課金処理された利用料その他の金員は、払い戻すことはできません。</li>
                                <li data-v-22f0b0fe="">お客様とお支払名義人が異なる決済方法はご利用頂けません。</li>
                                <li data-v-22f0b0fe="">
                                    選択された決済方法については、決済会社が定める利用規約等についてご確認のうえご利用ください。お客様と決済会社との間に生ずるトラブルについては、両者間で解決するものとし、当社は一切の責任を負いません。
                                </li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 6 </span>
                                解約（有料サービス）
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">
                                    お客様が有料サービスを解約する場合は、端末を通じて解約を行うこととします。なお、有料サービスは、お客様が解約を行った日よりご利用頂くことができなくなりますのでご注意ください。
                                </li>
                                <li data-v-22f0b0fe="">端末で行われた有料サービスの解約は全てお客様の意思で行なわれたものとします。</li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 7 </span>
                                免責
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">当社は、本サービスの内容及びそれにより提供される情報等について、その完全性、正確性、有用性等に関するいかなる保証も致しません。
                                </li>
                                <li data-v-22f0b0fe="">本サービスの利用に際し、当社以外の第三者からなされた行為又は提供されたサービスについて、当社は一切責任を負わないものとします。
                                </li>
                                <li data-v-22f0b0fe="">
                                    万一、当社が損害賠償責任を負う場合、当社の故意又は重過失による場合を除き、その範囲は、原因となった行為がなされた時点から過去１年間にお支払いいただいた利用料を上限とした現実かつ直接の損害に限り、当社の予見の有無にかかわらず、特別の事情から生じた損害、逸失利益、間接損害その他の損害については責任を負わないものとします。
                                </li>
                                <li data-v-22f0b0fe="">当社は、当社の帰責事由によらず本サービスが中止又は終了された場合には、お客様からのいかなる請求からも免責されるものとします。
                                </li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 8 </span>
                                本サービスの変更・中止
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">
                                    本サービスは、通信回線設備又はシステムの障害、定期又は緊急のメンテナンスの必要、天災、事変、戦争その他不可抗力の事態が生じ又は生ずる恐れがある場合、その他当社が中断又は中止を必要と判断したときは、事前の通知なくその一部又は全部が中断又は中止される事があります。当社は、本サービスが中断・中止しないことを保証しません。
                                </li>
                                <li data-v-22f0b0fe="">
                                    当社は、前項にかかわらず、当社の都合により、本サービスの内容を変更し、又はこれを中止・廃止ことができるものとし、その場合には、その30日前までに、端末を通じ又は他の方法によりお客様にご通知致します。ただし、細目的変更についてはこの限りではありません。
                                </li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 9 </span>
                                禁止行為
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">
                                    本サービスのご利用にあたり、お客様は、以下記載の行為を行わないものとします。
                                    <ol data-v-22f0b0fe="">
                                        <li data-v-22f0b0fe="">法令に違反する行為又はおそれのある行為</li>
                                        <li data-v-22f0b0fe="">公序良俗に反する行為又はおそれのある行為</li>
                                        <li data-v-22f0b0fe="">当社又は第三者の権利を侵害する行為</li>
                                        <li data-v-22f0b0fe="">当社もしくは第三者になりすます行為又は意図的に虚偽の情報を流布させる行為</li>
                                        <li data-v-22f0b0fe="">第三者の個人情報、登録情報等を不正に収集、開示又は提供する行為</li>
                                        <li data-v-22f0b0fe="">本サービスのサーバー、ネットワークシステムに支障を与える行為</li>
                                        <li data-v-22f0b0fe="">不当な問い合わせ又は要求をする行為</li>
                                        <li data-v-22f0b0fe="">営利目的の勧誘、政治･宗教への勧誘等、一般的に他のお客様が不快と感ずる行為</li>
                                        <li data-v-22f0b0fe="">反社会的勢力に対する利益供与その他の協力行為</li>
                                        <li data-v-22f0b0fe="">不正行為及びダーツプレイの結果を不当に改ざんする行為</li>
                                        <li data-v-22f0b0fe="">本規約に違反する行為</li>
                                        <li data-v-22f0b0fe="">その他、当社が本サービスの利用にあたり不適切と判断する行為</li>
                                    </ol>
                                </li>
                                <li data-v-22f0b0fe="">
                                    前項に該当する場合等、当社が不適切と判断した場合には、当社は､お客様への事前の通知なくアカウントの削除、コンテンツの削除、不正行為により得られたプレイ結果の削除等必要な対応ができるものとします。但し､当社はこれらを行う義務を負うものではありません。
                                </li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 10 </span>
                                解除
                            </h3>
                            <p data-v-22f0b0fe="" class="mb-0">
                                当社は、お客様が本規約のいずれかの規定に違反した場合、お客様に対する通知・催告をすることなく、本契約を一方的に解除することができるものとします。</p>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 11 </span>
                                分離条項
                            </h3>
                            <p data-v-22f0b0fe="" class="mb-0">
                                本規約又は本契約の一部が、消費者保護法令に規定される強行規定及び他の強行規定に抵触し、それにより効力を有しないとされる場合においても、当該抵触部分以外の規定は、引き続き効力を有するものとします。
                            </p>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 12 </span>
                                譲渡禁止
                            </h3>
                            <p data-v-22f0b0fe="" class="mb-0">お客様は、本契約に関する権利、地位又は利益を第三者に譲渡、貸与又は承継させてはならないものとします。</p>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 13 </span>
                                準拠法・合意管轄
                            </h3>
                            <ol data-v-22f0b0fe="">
                                <li data-v-22f0b0fe="">本規約及び本契約の成立、解釈、効力を含め、本規約及び本契約に関する一切の事項は、日本法に準拠するものとします。</li>
                                <li data-v-22f0b0fe="">
                                    お客様と当社との間で､本規約又は本契約に関する紛争が発生した場合､東京地方裁判所又は東京簡易裁判所を第1審の専属管轄裁判所とします。</li>
                            </ol>
                        </section>
                        <section data-v-22f0b0fe="">
                            <h3 data-v-22f0b0fe=""><span data-v-22f0b0fe="" class="sec-no"> 第 14 </span>
                                利用規約の変更
                            </h3>
                            <p data-v-22f0b0fe="" class="mb-0">
                                当社は今後､社会情勢の変化に対応するため､また事業推進の過程でサービス形態の変更があった場合､本規約を予告なく改訂､追加､変更することができるものとします。お客様は本規約の変更後、本サービスの利用を継続することにより、お客様は変更後の本規約に同意をしたものとします。
                            </p>
                        </section>
                        <div data-v-22f0b0fe="" class="text-right">
                            2005年4月7日制定 <br data-v-22f0b0fe="">
                            2011年4月20日改定 <br data-v-22f0b0fe="">
                            2014年1月20日改定 <br data-v-22f0b0fe="">
                            2017年12月11日改定 <br data-v-22f0b0fe="">
                            2018年10月23日改定 <br data-v-22f0b0fe="">
                            2019年4月2日改定 <br data-v-22f0b0fe="">
                            株式会社ダーツライブ
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>