<div class="channel-section" *ngIf="loading">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 col-12">
      <div class="channel-title">
        <h1>{{ channelData.title }}</h1>
      </div>
      <div>
        <div style="padding:10px;" [innerHTML]="channelData.description"></div>
      </div>
      <div *ngIf="channelData.coupons.length > 0" class="channel-logo">
        <div *ngFor="let coupon of channelData.coupons" id="channellogo">
          <a target="_blank" href="{{ coupon.link }}">
            <img [src]="coupon.image" alt="" />
          </a>
        </div>
      </div>
      <div class="archive" id="archivesection">
        <div class="row gap-0">
          <ng-container *ngFor="let item of channelData.children?.data; let i = index">
            <div class="col-lg-3 col-md-6 col-sm-12 padding-0">
              <a [ngClass]="{
                    'tag status premium': item.purchase_type == 'premium',
                    'tag status standard ': item.purchase_type == 'standard'
                  }" style="text-decoration: none; color: black" [routerLink]="['/channel', item.slug]">
                <div class="list">
                  <div class="items">

                    <div class="relative">
                      <img class="channel-img archive-thumbnail" [src]="item.banner_img" alt="" />
                      <div class="overlay">

                        <div class="overlay-count">
                          {{item.total_videos}}
                        </div>
                        <div>
                          <img src="./assets/play_btn.png" width="20px" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="archive-des">
                      <div class="wtags">
                        <div style="margin-right: 5px" *ngIf="item.show_tag" class="c-tag">
                          <img src="{{ item.show_tag }}" alt="tags" />
                        </div>
                        <div *ngIf="item.live_tag" class="live-tag">
                          <img src="{{ item.live_tag }}" alt="tags" class="dtag" />
                        </div>
                      </div>
                      <p class="des-title">{{ item.title }}</p>
                      <p class="date" [ngClass]="{ 'live-red': item.type == 'live' }">
                        <span *ngIf="item.type == 'video'" style="color: #818181">
                          {{ item.date | date : "yyyy-MM-dd" }}</span>
                        <span *ngIf="item.type == 'live'" style="color: red">
                          {{ item.start_time | date : "yyyy-MM-dd" }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="channelData.children?.data.length <= 0">
              <p> {{ "record_not_found" | translate }}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="!loading">
  <div class="channel-section">
    <div class="row">
      <div class="col-sm-10 offset-sm-1 col-12">
        <app-loader></app-loader>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="loading && error">
  <div class="channel-section">
    <div class="row">
      <div class="col-sm-10 offset-sm-1 col-12">
        error
      </div>
    </div>
  </div>
</ng-container>