<a [class]="item.purchase_type" [ngClass]="{
    'tag status premium': item.purchase_type == 'premium',
    'tag status standard ': item.purchase_type == 'standard'
  }" [routerLink]="[ '/channel/'+item.slug ]">

  <div class="relative">
    <img class="channel-img" src="{{ item.banner_img }}" alt="{{ item.title }}" />
    <div class="overlay">
      <div class="overlay-count">
        {{item.total_videos}}
      </div>
      <div>
        <img src="./assets/play_btn.png" width="20px" alt="">
      </div>
    </div>
  </div>

  <div class="description">
    <div class="textdate">
      <div class="tags">
        <div style="margin-right: 5px" *ngIf="item.show_tag" class="tag">
          <img src="{{ item.show_tag }}" alt="tags" />
        </div>
        <div *ngIf="item.live_tag" class="live-tag">
          <img src="{{ item.live_tag }}" alt="" />
        </div>
        <div *ngIf="item.is_ppv" class="live-tag">
          <img src="./assets/logo/ppv.png" alt="" />
        </div>
        <div></div>
      </div>
      <p class="text">{{ item.title }}</p>
      <p *ngIf="link == 'watch'" class="date">{{ item.date | date : "yyyy-MM-dd" }}</p>
      <p *ngIf="link == 'live-watch'" class="date">
        {{ item.start_time | date : "yyyy-MM-dd" }}
      </p>
    </div>
  </div>
</a>