<div class=" show" *ngIf="isMobile">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    
      <p>Matches is LIVE streaming {{videoData.multi_channel_count}} units being distrubated
        &nbsp;&nbsp;&nbsp;<span class="text-right" (click)="updateEvents()">
          <i class="fa fa-refresh"></i>
        </span>
      </p>
      
  </div>
  <div *ngIf="videoData.has_multi_channel">
    <div class="container">
      <div class="row">
        <div *ngFor="let data of multiLiveEvents" (click)="changeStream(data)" class="col-6"
          [ngClass]="{'mobile-active': current_machine_id === data.machine_id}">
          <img [src]="data.thumbnail" [ngClass]="{'mobile-active-class': current_machine_id === data.machine_id}"
            [alt]="data.title" class="img-fluid" />

          <p class="font-weight-bold mb-2 mt-2 text-center" *ngIf="data.machine_id">
            {{ data.cards }}
          </p>

          <p class="font-weight-bold mb-2 mt-2 text-center" *ngIf="!data.machine_id">
            Main Channel
          </p>

          <p class="mb-2">{{ data.date }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="show" style="overflow-x: hidden; max-height: 590px;" *ngIf="!isMobile">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 style="padding-left: 29px;">
      MultiLive &#946;

    </h2>
    <h2>
      <button class="text-right" (click)="updateEvents()" style="border: none; background: none;"><i
          class="fa fa-refresh"></i></button>
    </h2>

</div>
<div class="row" *ngIf="videoData.has_multi_channel" >
  <ng-container *ngIf="!isMobile">
    <div class="col-12" [ngClass]="{
        'active': current_machine_id == data.machine_id
      }" style="cursor: pointer; text-align: left;padding: 5px;" *ngFor="let data of multiLiveEvents"
      (click)="changeStream(data)">

      <div class="row">
        <div class="col-md-1" style="display: flex;
          align-items: center;
          justify-content: center;">
          <span *ngIf="current_machine_id == data.machine_id" style="font-size: 45px; margin-left: 10px; color: red;"><i class="fa fa-play"></i></span>
        </div>
        <div class="col-md-5">
          <img [src]="data.thumbnail" [ngClass]="{
                'active-class': current_machine_id == data.machine_id
              }" [alt]="data.title" width="100%" />
        </div>
        <div class="col-md-6">
          <p style="font-weight: 500; margin:0;" *ngIf="data.machine_id==0">
            {{ data.title }}
          </p>
          <p style="font-weight: 500; margin:0;" *ngIf="data.cards">
            {{ data.cards }}
          </p>
          <p style="font-weight: 500; margin:0;" *ngIf="!data.cards"></p>
            {{ data.game_round }}
          
          <p> {{ data.start_at | date:'yyyy-MM-dd' }}</p>
        </div>

      </div>

    </div>
  </ng-container>  
</div>
