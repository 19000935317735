<div *ngIf="loading" class="col-12 text-center">
  <img src="../../../../assets/loader/loader.gif" alt="loader" />
</div>

<div class="row">
  <div class="col-sm-10 offset-sm-1 col-12">
    <div *ngIf="!error">
      <div *ngIf="!loading" class="archive" id="archivesection">
        <div class="row gap-0">
          <div class="title">
            <p>{{ "sidebar.archives" | translate }}</p>
          </div>
          <div
            *ngFor="let item of channelData"
            class="col-lg-3 col-md-6 col-sm-12 padding-0 list"
            app-item
            [item]="item"
            [lang]="lang"
            [link]="'watch'"
          ></div>
        </div>
      </div>
      <!-- <div *ngIf="meta.current_page >= total_pages">
        <p class="text-center">{{ "sidebar.message" | translate }}</p>
      </div> -->
    </div>

    <div class="error" *ngIf="error">
      <p class="text-center">No Videos Avaialble !!</p>
      <div class="langbuttons">
        <button [routerLink]="['/']" type="submit" class="btn">
          <a><span class="material-icons"> chevron_left </span> 戻る</a>
        </button>
      </div>
    </div>
  </div>
</div>
