import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { DataShareService } from 'src/app/utilitis/services/data-share-services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  public show: any;
  public currentRoute: any;
  public showLoginButton: boolean = false;
  public english: any;
  public japan: any;
  constructor(
    public translate: TranslateService,
    public langSerivce: LanguageService,
    private dataService: DataShareService,
    private activatedRoute: ActivatedRoute
  ) {
    translate.setDefaultLang(this.langSerivce.getLang());
  }

  ngOnInit(): void {
    this.dataService.languageDisable.subscribe((res: any) => {
      // if(res) {
      this.showLoginButton = res;
      // }
    });
  }

  changeLanguagetojp(lang: string) {
    this.translate.use(lang);
    this.langSerivce.setLang(lang);
    this.japan = true;
    this.english = false;
    window.location.reload();
  }
  changeLanguagetoeg(lang: string) {
    this.translate.use(lang);
    this.langSerivce.setLang(lang);
    this.japan = false;
    this.english = true;
    window.location.reload();
  }

  openNewLink() {
    // window.location.href="https://www.dartslive.com/jp/external_transmission/"
    window.open("https://www.dartslive.com/jp/external_transmission/", '_blank');
  }
}
