import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArchivesComponent } from './components/pages/archives/archives.component';
import { ChannelComponent } from './components/pages/channel/channel.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LivePlayerComponent } from './components/pages/live-player/live-player.component';
import { LiveComponent } from './components/pages/live/live.component';
import { PagenotfoundComponent } from './components/pages/pagenotfound/pagenotfound.component';
import { PlayerComponent } from './components/pages/player/player.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { SearchresultComponent } from './components/pages/searchresult/searchresult.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { YoutubeComponent } from './components/pages/youtube/youtube.component';
import { ArchiveResolver } from './resolves/archive.resolver';
import { BannerResolver } from './resolves/banner.resolver';
import { CategoryResolver } from './resolves/category.resolver';
import { LiveResolver } from './resolves/live.resolver';
import { GuardService } from './utilitis/services/guard.service';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { ParentChannelComponent } from './components/pages/parent-channel/parent-channel.component';
import { LawsComponent } from './components/pages/laws/laws.component';
import { MultiLiveEventsComponent } from './components/pages/multi-live-events/multi-live-events.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    data: { name: 'home' },
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'channel/:id',
    component: ChannelComponent,
    // canActivate: [GuardService]
  },
  {
    path: 'parent-channel/:id',
    component: ParentChannelComponent,
    // canActivate: [GuardService]
  },
  {
    path: 'search',
    component: SearchresultComponent,
  },
  {
    path: 'watch',
    component: PlayerComponent,
  },
  {
    path: 'live-watch',
    component: LivePlayerComponent,
  },
  {
    path: 'live',
    component: LiveComponent,
  },
  {
    path: 'multi-live-events',
    component: MultiLiveEventsComponent,
  },
  
  {
    path: 'youtube',
    component: YoutubeComponent,
  },
  {
    path: 'youtube-play',
    component: YoutubePlayerComponent,
  },
  {
    path: '404',
    component: PagenotfoundComponent,
  },
  {
    path: 'archives',
    component: ArchivesComponent,
  },
  {
    path: 'archive',
    component: ArchivesComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'laws',
    component: LawsComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
